import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import Media from '../../utils/Media'
import { androidStoreUrl, iosStoreUrl } from '../../utils/StoreUrl'

const images = [
  '/images/common/badge-applestore.svg',
  '/images/common/badge-googleplay.png',
]

interface Props {
  size?: 'small' | 'medium'
}

const AppDownloadLinks: React.FC<Props> = ({ size = 'medium' }) => (
  <List>
    <li>
      <a
        href={iosStoreUrl('1527970346')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={images[0]}
          width={size === 'medium' ? '184' : '133'}
          height={size === 'medium' ? '55' : '39'}
          alt="App Store"
        />
      </a>
    </li>
    <li>
      <a
        href={androidStoreUrl('com.ryde_go.pass')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={images[1]}
          width={size === 'medium' ? '184' : '133'}
          height={size === 'medium' ? '55' : '39'}
          alt="Google Play Store"
        />
      </a>
    </li>
  </List>
)
export default AppDownloadLinks

const List = styled.ul`
  display: flex;
  align-items: center;

  li {
    a {
      transition: opacity 0.4s ease-out;
      &:hover {
        transition: opacity 0s;
        opacity: 0.6;
      }
    }
  }

  li + li {
    margin-left: 32px;
  }
  ${Media.mobileMiddle} {
    li + li {
      margin-left: 10px;
    }
  }
`
