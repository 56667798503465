import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

type MenuItemLinkProps = {
  href: string
  label: string
  current?: number
  blank?: boolean
  onFocus?: () => void
  onBlur?: () => void
}

const MenuItemLink: React.FC<MenuItemLinkProps> = ({
  href,
  label,
  current = 0,
  blank = false,
  onFocus = () => {},
  onBlur = () => {},
}) => (
  <StyledLink
    href={href}
    current={current}
    rel={blank ? 'noopener noreferrer' : undefined}
    target={blank ? '_blank' : undefined}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {label}
  </StyledLink>
)

export default MenuItemLink

const StyledLink = styled(Link)<{ current?: number }>`
  ${theme.typography.FontNotoMedium}
  display: inline-flex;
  position: relative;
  padding: 32px 8px;
  color: #000;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;

  ${Media.desktop} {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% + 16px);
      left: 50%;
      width: ${(props) => (props.current ? '100%' : '0')};
      height: 2px;
      transform: translate(-50%, 0);
      transition: width 0.1s ease-out;
      background: #000;
    }
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  ${Media.mobileMiddle} {
    padding: 0;
    line-height: 60px;
  }
`
