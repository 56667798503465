import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'
import ButtonRounded from './ButtonRounded'

type LinkProps = {
  label: string
  href: string
  blank?: boolean
}

interface Props {
  title: string
  description: string
  links: LinkProps[]
  tall?: boolean
}

const ContactPanel: React.FC<Props> = ({
  title,
  description,
  links,
  tall = false,
}) => (
  <Wrap tall={tall ? tall.toString() : undefined}>
    <div>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </div>
    <Btns>
      {links.map((link) => (
        <ButtonRounded
          key={`${link.href}`}
          href={link.href}
          icon="arrow"
          height="50"
          color="white"
          outline={true}
          blank={link.blank && link.blank}
        >
          {link.label}
        </ButtonRounded>
      ))}
    </Btns>
  </Wrap>
)
export default ContactPanel

interface WrapProps {
  tall: string | undefined
}

const Title = styled.div`
  transition: color 0.4s ease-out;
  ${theme.typography.FontOverpassBlack}
  color: #1e9af3;
  font-size: 24px;
  letter-spacing: 0.05em;

  ${Media.mobileMiddle} {
    font-size: 14px;
  }
`

const Description = styled.div`
  ${theme.typography.FontNotoMedium}
  margin-top: 11px;
  transition: color 0.4s ease-out;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.5;

  ${Media.mobileMiddle} {
    line-height: 1.9;
  }
`
const Btns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Media.mobileMiddle} {
    margin-top: 23px;
  }
`

const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 23px;
  height: ${(props) => (props.tall === 'true' ? '432px' : 'auto')};
  padding: 28px 10%;
  transition: background 0.4s ease-out;
  border: 2px solid #1e9af3;
  border-radius: 16px;

  ${Media.mobileMiddle} {
    height: auto;
    padding: 28px 15px;
  }

  ${Media.desktop} {
    &:hover {
      transition: background 0.1s ease-out;
      background-color: #1e9af3;

      ${Title} {
        transition: color 0.1s ease-out;
        color: #fff;
      }
      ${Description} {
        transition: color 0.1s ease-out;
        color: #fff;
      }
    }
  }
`
