import React from 'react'
import styled from 'styled-components'

import Media from '../../utils/Media'

interface Props {
  children: React.ReactNode
  gap?: '10' | '20' | '30' | '40' | '50'
  mobileWrap?: boolean
}

const Row: React.FC<Props> = ({ children, gap = '20', mobileWrap = false }) => (
  <Wrapper gap={gap} mobilewrap={mobileWrap.toString()}>
    {children}
  </Wrapper>
)

export default Row

interface WrapProps {
  gap: '10' | '20' | '30' | '40' | '50'
  mobilewrap: string
}

const Wrapper = styled.div<WrapProps>`
  display: flex;
  gap: ${(props) => `${props.gap}px`};

  ${Media.mobileMiddle} {
    display: ${(props) => (props.mobilewrap === 'true' ? 'block' : 'flex')};
  }
`
