import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  en: string
  ja: string
}

const DualLangHeading: React.FC<Props> = ({ en, ja }) => (
  <Wrap>
    <TitleEn>{en}</TitleEn>
    <TitleJa>{ja}</TitleJa>
  </Wrap>
)
export default DualLangHeading

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;

  ${Media.mobileMiddle} {
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
  }
`

const TitleEn = styled.h2`
  ${theme.typography.FontOverpassBlack}
  padding-top: 0.15em;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0.05em;

  ${Media.mobileMiddle} {
    font-size: 36px;
  }
`
const TitleJa = styled.span`
  ${theme.typography.FontNotoBold}
  color: #1E9AF3;
  font-size: 20px;
  font-weight: 700;

  ${Media.mobileMiddle} {
    font-size: 16px;
  }
`
