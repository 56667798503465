import React from 'react'
import styled from 'styled-components'

import Media from '../../utils/Media'
import ContactPanel from './ContactPanel'
import { Container } from './Container'
import DualLangHeading from './DualLangHeading'
import Row from './Row'

interface Props {
  path?: string
}

const ContactConversion: React.FC<Props> = () => (
  <Wrap>
    <Container>
      <Title>
        <DualLangHeading en="CONTACT" ja="お問い合わせはこちら" />
      </Title>
      <Grid>
        <Row gap="30" mobileWrap={true}>
          <GridCol>
            <ContactPanel
              title="FOR USER"
              description="使い方などに関するユーザーさま窓口になります。"
              links={[
                {
                  label: '問い合わせる',
                  href: 'https://form.run/@ryde-customer',
                },
              ]}
            />
          </GridCol>
          <GridCol>
            <ContactPanel
              title="FOR BUSINESS"
              description="RYDE PASSの導入や資料請求に関するお問い合わせはこちらから。"
              links={[
                {
                  label: 'RYDE PASSを導入する',
                  href: 'https://form.run/@ryde-pass-contact',
                  blank: true,
                },
                {
                  label: '資料請求をする',
                  href: 'https://form.run/@ryde-pass-download',
                },
              ]}
            />
          </GridCol>
        </Row>
      </Grid>
    </Container>
  </Wrap>
)
export default ContactConversion

const Wrap = styled.div`
  padding: 135px 0 80px 0;

  ${Media.mobileMiddle} {
    padding: 81px 0 80px 0;
  }
`

const Title = styled.div``

const Grid = styled.div`
  margin-top: 38px;
`

const GridCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 50%;

  ${Media.mobileMiddle} {
    gap: 24px;
    width: 100%;

    & + & {
      margin-top: 24px;
    }
  }
`
