import React from 'react'
import styled, { css } from 'styled-components'

import theme from '../../theme'

const lineHeight = 2
const lineSpacing = 4

const Burger = styled.a`
  margin: 0;
  padding: 20px;
  overflow: visible;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  text-transform: none;
  cursor: pointer;
`

const BurgerBox = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
`

const BurgerInner = styled.div.withConfig({
  shouldForwardProp: (props) => props !== '$isMenuActive',
})<{ $isMenuActive: boolean }>`
  top: 50%;
  margin-top: ${(lineHeight / 2) * -1}px;

  &:before,
  &:after {
    content: '';
    display: block;
  }
  &,
  &:before,
  &:after {
    position: absolute;
    width: 100%;
    height: ${lineHeight}px;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    background-color: ${theme.colors.brand};
  }
  &:before {
    top: ${(lineSpacing + lineHeight) * -1}px;
  }
  &:after {
    bottom: ${(lineSpacing + lineHeight) * -1}px;
  }

  /* stylelint-disable */
  &[data-active='false'] {
    background-color: ${theme.colors.brand};
  }
  /* stylelint-enable */

  ${(props) =>
    props.$isMenuActive &&
    css`
      & {
        background-color: transparent;
      }
      &::before {
        top: calc(50% - 1px);
        transform: rotate(45deg);
      }
      &::after {
        top: calc(50% - 1px);
        transform: rotate(-45deg);
      }
    `}
`

interface Props {
  className?: string
  // eslint-disable-next-line no-undef
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  isMenuActive: boolean
}

const Hamburger: React.FC<Props> = ({
  className,
  onClick,
  isMenuActive,
}: Props) => (
  <Burger onClick={onClick} className={className} data-active={isMenuActive}>
    <BurgerBox>
      <BurgerInner
        color="dark"
        $isMenuActive={isMenuActive}
        data-active={isMenuActive}
      />
    </BurgerBox>
  </Burger>
)

export default Hamburger
