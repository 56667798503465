import NextLink from 'next/link'
import React from 'react'
import styled from 'styled-components'

import BlankIcon from '../../assets/svgs/blank.svg'
import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  children: React.ReactNode
  href: string
  width?: 'auto' | '232' | '320'
  height?: '46' | '50' | '60' | '72'
  color?: 'white' | 'black' | 'blue' | 'lightblue'
  icon?: null | 'blank' | 'arrow'
  outline?: boolean
  blank?: boolean
}

const getIcon = (target: string) => {
  switch (target) {
    case 'blank':
      return <BlankIcon />
    case 'arrow':
      return <Arrow className="arrow" />
    default:
      return <></>
  }
}

const ButtonRounded: React.FC<Props> = ({
  children,
  href,
  width = 'auto',
  height = '60',
  color = 'white',
  icon = null,
  outline = false,
  blank = false,
}) => (
  <Btn
    href={href}
    width={width}
    height={height}
    color={color}
    outline={outline ? outline.toString() : undefined}
    {...(blank ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
  >
    {children}
    {icon && getIcon(icon)}
  </Btn>
)

export default ButtonRounded

interface BtnProps {
  href: string
  width: 'auto' | '232' | '320'
  height: '46' | '50' | '60' | '72'
  color: 'white' | 'black' | 'blue' | 'lightblue'
  outline: string | undefined
}
const Btn = styled(NextLink)<BtnProps>`
  ${theme.typography.FontNotoBold}
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width === 'auto' ? 'auto' : `${props.width}px`)};
  height: ${(props) => `${props.height}px`};
  /* stylelint-disable */
  border: ${(props) =>
    props.outline === 'true'
      ? `2px solid ${colorSetting[props.color].text}`
      : `2px solid ${colorSetting[props.color].bg}`};
  /* stylelint-enable */
  border-radius: 8px 8px;
  background-color: ${(props) => colorSetting[props.color].bg};
  color: ${(props) => colorSetting[props.color].text};
  gap: 10px;
  text-decoration: none;

  svg {
    path {
      stroke: ${(props) => colorSetting[props.color].text};
    }
  }

  .arrow {
    border-top: 1px solid ${(props) => colorSetting[props.color].text};
    border-right: 1px solid ${(props) => colorSetting[props.color].text};
  }

  ${Media.desktop} {
    transition: 0.4s ease-out;

    .arrow {
      transition: 0.4s ease-out;
    }

    svg {
      path {
        transition: 0.4s ease-out;
      }
    }
    &:hover {
      transition: 0.01s ease-out;
      background-color: ${(props) => colorSetting[props.color].hoverBg};
      color: ${(props) => colorSetting[props.color].hoverText};

      .arrow {
        transition: 0.01s ease-out;
        border-color: ${(props) => colorSetting[props.color].hoverText};
      }

      svg {
        path {
          transition: 0.01s ease-out;
          stroke: ${(props) => colorSetting[props.color].hoverText};
        }
      }
    }
  }
`

const Arrow = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  right: 24px;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  transform: translate(0, -50%) rotate(45deg);
  border-top: 1px solid #003687;
  border-right: 1px solid #003687;
`

const colorSetting = {
  blue: {
    bg: '#003687',
    text: '#fff',
    hoverBg: '#fff',
    hoverText: '#003687',
  },
  lightblue: {
    bg: '#1e9af3',
    text: '#fff',
    hoverBg: '#fff',
    hoverText: '#1e9af3',
  },
  white: {
    bg: '#fff',
    text: '#003687',
    hoverBg: '#003687',
    hoverText: '#fff',
  },
  black: {
    bg: '#000',
    text: '#fff',
    hoverBg: '#fff',
    hoverText: '#000',
  },
}
