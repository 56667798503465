import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  show: boolean
}

export const PulldownMenu: React.FC<Props> = ({ show = false }) => {
  const router = useRouter()
  return (
    <StyledDiv className={show ? 'fadein' : ''}>
      <Btn href="/" className={router.pathname === '/' ? 'current' : ''}>
        <span>サービス概要</span>
      </Btn>
      <Btn
        href="/usage"
        className={router.pathname.startsWith('/usage') ? 'current' : ''}
      >
        <span>RYDE PASSの活用</span>
      </Btn>
      <Btn
        href="/foundation"
        className={router.pathname.startsWith('/foundation') ? 'current' : ''}
      >
        <span>RYDE PASSの基盤を活用</span>
      </Btn>
      <Btn
        href="/introduction"
        className={router.pathname.startsWith('/introduction') ? 'current' : ''}
      >
        <span>導入に関して</span>
      </Btn>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  @keyframes fadeIn {
    0% {
      display: block;
      opacity: 1;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      display: block;
      opacity: 1;
    }

    99% {
      display: block;
      opacity: 0;
    }

    100% {
      display: none;
      opacity: 0;
    }
  }

  transform: translate3d(0, 0, 0);

  ${Media.desktop} {
    display: none;
    position: absolute;
    top: 58px;
    left: 12px;
    width: 260px;
    overflow: hidden;
    animation: fadeOut 0.2s ease-out;
    border-radius: 8px;
    opacity: 0;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);

    &.fadein {
      display: block;
      animation: fadeIn 0.1s ease-out;
      opacity: 1;
    }
  }

  ${Media.mobileTablet} {
    width: 80vw;
  }
`

const Btn = styled(Link)`
  ${theme.typography.FontNotoMedium}
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  padding-left: 14px;
  transition: background 0.35s ease-out;
  outline: none;
  letter-spacing: 0.1em;
  text-decoration: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 17px;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    transform: translate(0, -50%) rotate(45deg);
    border-top: 1px solid ${theme.colors.white};
    border-right: 1px solid ${theme.colors.white};
  }

  ${Media.desktop} {
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};
    &:hover,
    &.current,
    &:focus,
    &:active {
      transition: background 0.1s ease-out;
      background-color: ${theme.colors.white};
      color: ${theme.colors.darkBlue};

      &::after {
        border-color: ${theme.colors.darkBlue};
      }
    }
    &.current {
      pointer-events: none;
    }
  }
  ${Media.mobileTablet} {
    color: ${theme.colors.darkBlue};

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 10px;
      height: 10px;
      transform: translate(0, -50%) rotate(-45deg);
      border-right: 1px solid ${theme.colors.darkBlue};
      border-bottom: 1px solid ${theme.colors.darkBlue};
    }
  }
`
