import { css } from 'styled-components'

const hoverOpacity = (opacity: number = 0.3, duration: number = 0.3) => css`
  transition: opacity ${duration || 0.3}s;
  &:hover {
    opacity: ${opacity || 0.3};
  }
`

export default hoverOpacity
