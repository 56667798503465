import styled, { css } from 'styled-components'

import Media from '../../utils/Media'

type Size = 'small' | 'middle' | 'large' // default is large

const containerWidth = (size: Size) => {
  switch (size) {
    case 'small':
      return 768
    case 'middle':
      return 1000
    case 'large':
      return 1088
    default:
      return '' as never
  }
}

export interface ContainerProps {
  size?: Size
}

export const ContainerStyle = css<ContainerProps>`
  max-width: ${(props) => containerWidth(props.size || 'large')}px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
  padding-left: 32px;

  ${Media.mobile} {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Container = styled.div<{ size?: Size }>`
  ${ContainerStyle};
`
