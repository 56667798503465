export default {
  brand: 'rgba(0,0,0,1)',
  white: 'rgba(255,255,255,1)',
  textBlackPrimary: 'rgba(0,0,0,1)',
  textBlackSecondary: 'rgba(0,0,0,0.6)',
  textBlackTertiary: 'rgba(0,0,0,0.4)',
  textLink: '#338CF4',
  textWhitePrimary: 'rgba(255,255,255,1)',
  textWhiteSecondary: 'rgba(255,255,255,0.6)',
  textWhiteTertiary: 'rgba(255,255,255,0.4)',
  divider: 'rgba(0, 0, 0, 0.2)',
  bgGray: 'rgba(240, 240, 240, 1)',
  bgBlackInner: 'rgba(29, 29, 29, 1)',
  bgDarkGray: 'rgba(46,46,46,1)',
  bgBlack: 'rgba(17, 17, 17, 1)',
  borderGray: 'rgba(0, 0, 0, 0.2)',
  borderWhite: 'rgba(255,255,255,0.2)',
  primary: '#1E9AF3',
  lightBlue: '#1E9AF3',
  darkBlue: '#003687',
}
