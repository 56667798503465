import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

const onClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

interface Props {
  className?: string
}

const ScrollToTop: React.FC<Props> = ({ className }: Props) => (
  <Wrapper onClick={onClick} className={className} />
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  transition:
    background 0.2s,
    color 0.2s,
    fill 0.2s;
  border: 2px solid ${theme.colors.primary};
  border-radius: 50%;
  background-color: ${theme.colors.white};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    transform: translate(0, 4px) rotate(-45deg);
    border-top: 1px solid ${theme.colors.primary};
    border-right: 1px solid ${theme.colors.primary};
  }

  svg {
    width: 22px;
    transform: rotate(-90deg);
  }

  &:hover {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    &::after {
      border-color: ${theme.colors.white};
    }
  }

  ${Media.mobileMiddle} {
    width: 60px;
    height: 60px;
  }
`

export default ScrollToTop
